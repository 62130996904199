import * as React from "react"
import { Link, graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {
  function _onSubmit(e) {
    console.log(e.target)
  }

  function _onChange(e) {
    navigate(`/planning/${e.target.value.toLowerCase()}`)
  }

  return (
    <Layout>
      <Seo title="TripPlanR: Vos plannings de vacances sur mesure" />

      <div className="hero">
        <h1>
          <span className="textOrange">Ne cherchez plus les bons plans</span>,
          <br />
          ils sont dans votre poche <span className="textOrange">!</span>
        </h1>
        <h2>Où souhaitez-vous partir cet été&nbsp;?</h2>
        <form onSubmit={_onSubmit} className="destinationForm">
          <select name="destination" onChange={_onChange}>
            <option disabled hidden selected>
              Découvrez nos destinations
            </option>
            {data.allCitiesJson.nodes.map(node => (
              <option>{node.name}</option>
            ))}
          </select>
        </form>
        <p className="smallForm">
          Découvrez nos plannings à succès, ou créez le vôtre avec nous.
        </p>
      </div>

      <div className="homeSection accent">
        <h2>
          Qui connaît mieux les bons plans d’une ville que ses habitants ?
        </h2>
        <p>
          TripPlanR est la plateforme regroupant les meilleurs bons plans des
          habitants dans votre poche !
          <br />
          Nous relions en 1 clic les activités, restaurants et bars sur votre
          smartphone.
          <br />
          Ne perdez plus votre temps, ni votre argent à rechercher le restuarant
          parfait ou l'activité de vos rêves.
          <br />
        </p>
      </div>
      <div className="homeSection">
        <h2>Quelques exemples de plannings</h2>

        <div className="planningRow">
          <div className="phoneColumn">
            <StaticImage
              src="../images/phone.png"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
            />
          </div>
          <div className="planningColumn">
            <div className="planningLine">
              <Link to={`planning/barcelone`}>
                <StaticImage
                  src="../images/cities/photo2.png"
                  height={150}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                />
              </Link>
              <div className="infoColumn">
                <h3>
                  Sorties à <span className="cityName">Barcelone</span>
                </h3>
                <ul className="infoList">
                  <li>5 jours</li>
                  <li>7 activités</li>
                  <li>10 restaurants</li>
                  <li>5 bars</li>
                </ul>
                <Link to={`planning/barcelone`}>C'est parti !</Link>
              </div>
            </div>

            <div className="planningLine">
              <Link to={`planning/paris`}>
                <StaticImage
                  src="../images/cities/photo1.png"
                  height={150}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                />
              </Link>
              <div className="infoColumn">
                <h3>
                  Visites à <span className="cityName">Paris</span>
                </h3>
                <ul className="infoList">
                  <li>5 jours</li>
                  <li>17 activités</li>
                  <li>10 restaurants</li>
                  <li>2 bars</li>
                </ul>
                <Link to={`planning/paris`}>C'est parti !</Link>
              </div>
            </div>

            <div className="planningLine">
              <Link to={`planning/porto`}>
                <StaticImage
                  src="../images/cities/photo3.png"
                  height={150}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                />
              </Link>
              <div className="infoColumn">
                <h3>
                  Détente à <span className="cityName">Porto</span>
                </h3>
                <ul className="infoList">
                  <li>5 jours</li>
                  <li>8 activités</li>
                  <li>10 restaurants</li>
                  <li>1 bar</li>
                </ul>
                <Link to={`planning/porto`}>C'est parti !</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="homeSection accent">
        <h2>Envie d'un planning sur mesure ?</h2>
        <p>Fixons un rendez-vous pour créer votre planning de rêve !</p>
        <a
          className="button"
          href="https://calendly.com/tripplanr-b2c/45m-call-b2c"
          target="_blank"
          rel="noreferrer"
          title="Découvrez notre solution"
        >
          Parlons-en
        </a>
      </div>

      <div className="homeSection">
        <h2>Votre assistant personnel</h2>
        <p>
          Déjà +2.000 plannings téléchargés !
          <br />
          Téléchargez nos plannings de 5 jours, conçus par des amoureux de bons
          plans !
          <br />
          Découvrez nos city trip hors du commun dans 20 villes en Europe.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allCitiesJson(sort: { fields: name }) {
      nodes {
        internal {
          type
        }
        name
      }
    }
  }
`

export default IndexPage
